import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "search",
      "style": {
        "position": "relative"
      }
    }}>{`Search`}</h1>
    <p>{`Search enables users to specify a word or a phrase to find particularly relevant pieces of content without the use of navigation.`}</p>
    <p><strong parentName="p">{`Filter`}</strong>{`: Filtering can be used as a way to further narrow down content on the page based on the user's search query. Filtering does not replace Ordination.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Use labels to show that the results are being displayed. And present the most relevant results first.`}</li>
      <li parentName="ul">{`For searches with many results use the Paging component and indicate how many results were found.`}</li>
      <li parentName="ul">{`Display clear messages when no results are found and offer other search suggestions whenever possible.`}</li>
      <li parentName="ul">{`Keep the original text in the input field. It facilitates the user's action if he wants to make a new search with some modifications in the input.`}</li>
      <li parentName="ul">{`If it is possible to detect a typing error, show the results for the word "corrected", this avoids the frustration of not obtaining results and does not force the user to perform a new search.`}</li>
      <li parentName="ul">{`Where appropriate, highlight the search terms in the results.`}</li>
    </ul>
    <h2 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}</h2>
    <Demo src='pages/components/search/Search' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      